import React, {useState,  useEffect } from 'react';

import Configs from "../../Configs";
import * as apiCalls from "../../common/apiCalls";

import { t } from 'i18next';

// ---------------------------------------------------------------------------------------------------
const SlackOnboarding = () => {
    const [message, setMessage] = useState("Connecting you to Slack...");
    const [error, setError] = useState(false);

    // Get the code parameter from the URL
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const error = urlParams.get('error');
        
        if (Configs.devEnvironment) console.log("[slackOnboarding.js][useEffect()] code received from Slack:", code);

        if (code) {
            
            getSlackAccessToken(code)
            .then((response) => {
                if (response) {
                    if (Configs.devEnvironment) console.log("[slackOnboarding.js][useEffect()] response from getSlackAccessToken():", response);

                    // Set new state for content being successfull
                    setMessage("Slack account connected successfully!");
                } else {
                    if (Configs.devEnvironment) console.log("[slackOnboarding.js][useEffect()] Error getting access token from Slack");
                }
            })
            .catch((error) => {
                console.error("[slackOnboarding.js][useEffect()] Error getting access token from Slack:", error);
            });

        }

        if (error) {
            setMessage("Having some doubts? Reach out to us to clarify them: ");
            setError(true);
        }


    }, []);

    // ---------------------------------------------------------------------------------------------------
    // Slack API calls
    // ---------------------------------------------------------------------------------------------------
    async function getSlackAccessToken(code) {
        const clientId = Configs.slackClientId;
        const clientSecret = Configs.slackClientSecret;

        try {
            const response = await apiCalls.getAccessTokenFromSlack(code, clientId, clientSecret);
            if (Configs.devEnvironment) console.log("[slackOnboarding.js][getSlackAccessToken()] response:", response);
    
            if (response?.ok) {
                // Send the response data to backend, and store the access token in the database for future use
                const data = {
                    slackResponse: response,
                    timestamp: new Date().toISOString()
                }

                if (Configs.devEnvironment) console.log("[slackOnboarding.js][getSlackAccessToken()] data to send to backend:", data);

                const result = await apiCalls.sendSlackAccessTokenToBackend(data);

                if (Configs.devEnvironment) console.log("[slackOnboarding.js][getSlackAccessToken()] result from sendSlackAccessTokenToBackend():", result);

                return result;
            } 
        } catch (error) {
            console.error("[slackOnboarding.js][getSlackAccessToken()] Error getting access token from Slack:", error);
            return null;
        }
    }

    // ---------------------------------------------------------------------------------------------------
    // Render
    // ---------------------------------------------------------------------------------------------------
    return (
        <div className="flex flex-col lg:p-12 p-4 min-h-screen bg-gradient-to-b from-green-200 to-white">

                <div className='flex flex-col justify-start items-center bg-white h-full
                border border-gray-100 shadow-xl 
                m-2 sm:m-20 p-12 lg:p-12 rounded lg:max-w-screen-xl'>
                    {/* KC logo */}
                    <div className='flex flex-col md:flex-row justify-start items-center'>
                        <div className="flex container w-[180px] h-[180px]">
                            <img className=" object-center object-contain" alt="Kincode-App-Logo" src={Configs.companyLogoLarge}
                            />
                        </div>
                    </div>

                    {/* Message */}
                    <h1 className='section-title py-4'>{message}</h1>

                    {error && 
                            <iframe
                                title={'hubspot'}
                                className='w-full h-full'
                                src={'https://meetings-eu1.hubspot.com/miguel-alvarez?embed=true'}
                            />
                    }

                </div>
        </div>
    );
};

export default SlackOnboarding;